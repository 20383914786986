exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-ai-llm-tsx": () => import("./../../../src/pages/ai-llm.tsx" /* webpackChunkName: "component---src-pages-ai-llm-tsx" */),
  "component---src-pages-blog-post-tsx": () => import("./../../../src/pages/blog-post.tsx" /* webpackChunkName: "component---src-pages-blog-post-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-case-studies-tsx": () => import("./../../../src/pages/case-studies.tsx" /* webpackChunkName: "component---src-pages-case-studies-tsx" */),
  "component---src-pages-casestudyimages-tsx": () => import("./../../../src/pages/casestudyimages.tsx" /* webpackChunkName: "component---src-pages-casestudyimages-tsx" */),
  "component---src-pages-cohuman-solution-tsx": () => import("./../../../src/pages/cohuman-solution.tsx" /* webpackChunkName: "component---src-pages-cohuman-solution-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-devsecops-tsx": () => import("./../../../src/pages/devsecops.tsx" /* webpackChunkName: "component---src-pages-devsecops-tsx" */),
  "component---src-pages-emerging-skills-tsx": () => import("./../../../src/pages/emerging-skills.tsx" /* webpackChunkName: "component---src-pages-emerging-skills-tsx" */),
  "component---src-pages-homepage-tsx": () => import("./../../../src/pages/homepage.tsx" /* webpackChunkName: "component---src-pages-homepage-tsx" */),
  "component---src-pages-image-tsx": () => import("./../../../src/pages/image.tsx" /* webpackChunkName: "component---src-pages-image-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-edtech-tsx": () => import("./../../../src/pages/industries-edtech.tsx" /* webpackChunkName: "component---src-pages-industries-edtech-tsx" */),
  "component---src-pages-industries-fintech-tsx": () => import("./../../../src/pages/industries-fintech.tsx" /* webpackChunkName: "component---src-pages-industries-fintech-tsx" */),
  "component---src-pages-industries-healthtech-tsx": () => import("./../../../src/pages/industries-healthtech.tsx" /* webpackChunkName: "component---src-pages-industries-healthtech-tsx" */),
  "component---src-pages-industries-hitech-tsx": () => import("./../../../src/pages/industries-hitech.tsx" /* webpackChunkName: "component---src-pages-industries-hitech-tsx" */),
  "component---src-pages-industries-martech-tsx": () => import("./../../../src/pages/industries-martech.tsx" /* webpackChunkName: "component---src-pages-industries-martech-tsx" */),
  "component---src-pages-new-case-study-tsx": () => import("./../../../src/pages/new-case-study.tsx" /* webpackChunkName: "component---src-pages-new-case-study-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-saas-application-tsx": () => import("./../../../src/pages/saas-application.tsx" /* webpackChunkName: "component---src-pages-saas-application-tsx" */),
  "component---src-pages-saas-bundle-offerings-tsx": () => import("./../../../src/pages/saas-bundle-offerings.tsx" /* webpackChunkName: "component---src-pages-saas-bundle-offerings-tsx" */),
  "component---src-pages-saas-implementation-tsx": () => import("./../../../src/pages/saas-implementation.tsx" /* webpackChunkName: "component---src-pages-saas-implementation-tsx" */),
  "component---src-pages-services-rpa-tsx": () => import("./../../../src/pages/services-rpa.tsx" /* webpackChunkName: "component---src-pages-services-rpa-tsx" */),
  "component---src-pages-solution-api-dev-integration-tsx": () => import("./../../../src/pages/solution-api-dev-integration.tsx" /* webpackChunkName: "component---src-pages-solution-api-dev-integration-tsx" */),
  "component---src-pages-solution-mobile-application-tsx": () => import("./../../../src/pages/solution-mobile-application.tsx" /* webpackChunkName: "component---src-pages-solution-mobile-application-tsx" */),
  "component---src-pages-solution-quality-assurance-tsx": () => import("./../../../src/pages/solution-quality-assurance.tsx" /* webpackChunkName: "component---src-pages-solution-quality-assurance-tsx" */),
  "component---src-pages-solution-uiux-tsx": () => import("./../../../src/pages/solution-uiux.tsx" /* webpackChunkName: "component---src-pages-solution-uiux-tsx" */),
  "component---src-pages-sso-solution-tsx": () => import("./../../../src/pages/sso-solution.tsx" /* webpackChunkName: "component---src-pages-sso-solution-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-transforming-logistics-and-inventory-management-tsx": () => import("./../../../src/pages/transforming-logistics-and-inventory-management.tsx" /* webpackChunkName: "component---src-pages-transforming-logistics-and-inventory-management-tsx" */),
  "component---src-templates-allcasestudy-tsx": () => import("./../../../src/templates/allcasestudy.tsx" /* webpackChunkName: "component---src-templates-allcasestudy-tsx" */),
  "component---src-templates-allwhitepapers-tsx": () => import("./../../../src/templates/allwhitepapers.tsx" /* webpackChunkName: "component---src-templates-allwhitepapers-tsx" */),
  "component---src-templates-blog-author-js": () => import("./../../../src/templates/blog-author.js" /* webpackChunkName: "component---src-templates-blog-author-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-casestudy-tsx": () => import("./../../../src/templates/casestudy.tsx" /* webpackChunkName: "component---src-templates-casestudy-tsx" */),
  "component---src-templates-jobrolepage-tsx": () => import("./../../../src/templates/jobrolepage.tsx" /* webpackChunkName: "component---src-templates-jobrolepage-tsx" */)
}

